import React from 'react';
import { ReactComponent as IconArrow } from './assets/icons/arrow.svg';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <a href="https://miltykh.com/">Ilia Miltykh</a>
          </div>
          <div className="social">
            <a href="https://miltykh.com/" title="Ilia Miltykh Website" target="_blank" rel="noopener noreferrer">
              <IconArrow className="icon" />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Что таят сосуды внутри органов?</h1>
            <p>Сайт скоро появится. Пожалуйста, заглядывайте, чтобы узнать больше. Напишите нам, если есть вопросы.</p>
          </div>
          <a href="mailto:vasculature@miltykh.com">
            <div className="cta">Связаться!</div>
          </a>
        </div>
      </div>
    );
  }
}

export default App;